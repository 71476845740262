var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-container", [
        _c(
          "form",
          { ref: "comarcaForm" },
          [
            _c(
              "v-form",
              {
                ref: "form",
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.salvarComarca.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.valid,
                  callback: function($$v) {
                    _vm.valid = $$v
                  },
                  expression: "valid"
                }
              },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticStyle: { display: "grid" },
                        attrs: { cols: "12", sm: "3", md: "3" }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "grey--text ma-3 pa-3 mx-auto my-auto",
                            attrs: {
                              size: _vm.getIconSize(),
                              "elevation-8": ""
                            }
                          },
                          [_vm._v(" mdi-map-search-outline ")]
                        ),
                        _c(
                          "v-card-text",
                          {
                            staticClass: "grey--text font-weight-black",
                            staticStyle: {
                              "font-size": "32px",
                              display: "flex",
                              "justify-content": "center",
                              "align-items": "center"
                            }
                          },
                          [
                            _vm._v(" Detalhes "),
                            _vm.isSmall || _vm.isMedium ? _c("br") : _vm._e(),
                            _vm.isSmall || _vm.isMedium ? _c("br") : _vm._e(),
                            _vm._v("da "),
                            _c("br"),
                            _c("br"),
                            _vm._v(" Comarca ")
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "9", md: "9" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                class: _vm.isExtraSmall ? "px-3 py-0" : "",
                                attrs: { cols: "12", sm: "6", md: "6" }
                              },
                              [
                                _c(
                                  "v-card-text",
                                  {
                                    staticClass: "grey--text font-weight-bold"
                                  },
                                  [_vm._v(" Código da Comarca ")]
                                ),
                                _c("v-text-field", {
                                  attrs: {
                                    "prepend-icon": "mdi-sticker-text",
                                    name: "codComarca",
                                    label: "Código",
                                    type: "text",
                                    disabled: "",
                                    solo: "",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.comarca.cd_ibge_comarca,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.comarca,
                                        "cd_ibge_comarca",
                                        $$v
                                      )
                                    },
                                    expression: "comarca.cd_ibge_comarca"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                class: _vm.isExtraSmall ? "px-3 py-0" : "",
                                attrs: { cols: "12", sm: "6", md: "6" }
                              },
                              [
                                _c(
                                  "v-card-text",
                                  {
                                    staticClass: "grey--text font-weight-bold"
                                  },
                                  [_vm._v(" UF da Comarca ")]
                                ),
                                _c("v-text-field", {
                                  attrs: {
                                    "prepend-icon": "mdi-sticker-text",
                                    name: "CodUF",
                                    label: "Código da UF",
                                    type: "text",
                                    disabled: "",
                                    solo: "",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.comarca.cd_uf,
                                    callback: function($$v) {
                                      _vm.$set(_vm.comarca, "cd_uf", $$v)
                                    },
                                    expression: "comarca.cd_uf"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                class: _vm.isExtraSmall ? "px-3 py-0" : "",
                                attrs: { cols: "12", sm: "12", md: "12" }
                              },
                              [
                                _c(
                                  "v-card-text",
                                  {
                                    staticClass: "grey--text font-weight-bold"
                                  },
                                  [_vm._v(" Nome da Comarca ")]
                                ),
                                _c("v-text-field", {
                                  attrs: {
                                    "prepend-icon": "mdi-sticker-text",
                                    name: "NomeComarca",
                                    label: "Nome da Comarca",
                                    type: "text",
                                    disabled: "",
                                    solo: "",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.comarca.nm_comarca,
                                    callback: function($$v) {
                                      _vm.$set(_vm.comarca, "nm_comarca", $$v)
                                    },
                                    expression: "comarca.nm_comarca"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                class: _vm.isExtraSmall ? "px-3 py-0" : "",
                                attrs: { cols: "12", sm: "12", md: "12" }
                              },
                              [
                                _c(
                                  "v-card-text",
                                  {
                                    staticClass: "grey--text font-weight-bold"
                                  },
                                  [_vm._v(" Nome do Município ")]
                                ),
                                _c("v-text-field", {
                                  attrs: {
                                    "prepend-icon": "mdi-sticker-text",
                                    name: "NomeMunicipio",
                                    label: "Nome do Município",
                                    type: "text",
                                    disabled: "",
                                    solo: "",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.comarca.nm_municipio,
                                    callback: function($$v) {
                                      _vm.$set(_vm.comarca, "nm_municipio", $$v)
                                    },
                                    expression: "comarca.nm_municipio"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "8", md: "8" } },
                              [
                                _c(
                                  "v-card-text",
                                  {
                                    staticClass: "grey--text font-weight-bold",
                                    staticStyle: { "font-size": "24px" }
                                  },
                                  [
                                    _vm._v(" Limite Diário de envios "),
                                    !_vm.isExtraSmall && !_vm.isSmall
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "grey lighten-2\n "
                                                              }
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [_vm._v(" help ")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2546100846
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Informe no campo ao lado a quantidade envios que essa comarca irá realizar diariamente."
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "grid-center",
                                attrs: { cols: "12", sm: "4", md: "4" }
                              },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "grid-center" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mx-2",
                                            attrs: {
                                              fab: "",
                                              dark: "",
                                              "x-small": "",
                                              color: "primary"
                                            },
                                            on: { click: _vm.aumentarLimite }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { dark: "" } },
                                              [_vm._v(" mdi-plus ")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mx-2 mt-1",
                                            attrs: {
                                              fab: "",
                                              dark: "",
                                              "x-small": "",
                                              color: "primary"
                                            },
                                            on: { click: _vm.diminuirlimite }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { dark: "" } },
                                              [_vm._v(" mdi-minus ")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-text-field", {
                                      directives: [
                                        {
                                          name: "maska",
                                          rawName: "v-maska",
                                          value: "####",
                                          expression: "'####'"
                                        }
                                      ],
                                      staticClass:
                                        "display-1 font-weight-light pa-3 mx-auto input-limite-diario",
                                      attrs: {
                                        name: "limDiario",
                                        oninput:
                                          "if(this.value < 0) this.value = 0;",
                                        "hide-details": "",
                                        height: "72",
                                        filled: "",
                                        solo: "",
                                        required: ""
                                      },
                                      on: {
                                        focus: function($event) {
                                          return _vm.focusInput($event)
                                        }
                                      },
                                      model: {
                                        value: _vm.comarca.ds_limite_diario,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.comarca,
                                            "ds_limite_diario",
                                            $$v
                                          )
                                        },
                                        expression: "comarca.ds_limite_diario"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                class: _vm.isExtraSmall ? "px-3 pt-3 pb-0" : "",
                                attrs: { cols: "12", sm: "8", md: "8" }
                              },
                              [
                                _c(
                                  "v-card-text",
                                  {
                                    staticClass: "grey--text font-weight-bold",
                                    staticStyle: { "font-size": "24px" }
                                  },
                                  [
                                    _vm._v("Status da Comarca "),
                                    !_vm.isExtraSmall && !_vm.isSmall
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "grey lighten-2\n "
                                                              }
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [_vm._v(" help ")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2546100846
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Selecione no campo ao lado para definir se a comarca está ativa ou inativa"
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "flex-center",
                                class: _vm.isExtraSmall ? "px-3 pt-0 pb-3" : "",
                                attrs: { cols: "12", sm: "4", md: "4" }
                              },
                              [
                                _c("v-switch", {
                                  staticClass: "ma-3",
                                  attrs: {
                                    color: "success",
                                    inset: "",
                                    "hide-details": "",
                                    label: "Ativo/Inativo",
                                    "data-cy": "btnStatusComarca"
                                  },
                                  model: {
                                    value: _vm.comarca.st_ativo,
                                    callback: function($$v) {
                                      _vm.$set(_vm.comarca, "st_ativo", $$v)
                                    },
                                    expression: "comarca.st_ativo"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                class: _vm.isExtraSmall ? "px-3 pt-3 pb-0" : "",
                                attrs: { cols: "12", sm: "8", md: "8" }
                              },
                              [
                                _c(
                                  "v-card-text",
                                  {
                                    staticClass: "grey--text font-weight-bold",
                                    staticStyle: { "font-size": "24px" }
                                  },
                                  [
                                    _vm._v("Comarca requer Endosso "),
                                    !_vm.isExtraSmall && !_vm.isSmall
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "grey lighten-2\n "
                                                              }
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [_vm._v(" help ")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2546100846
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Selecione no campo ao lado para definir se a comarca requer endosso ou não"
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "flex-center",
                                class: _vm.isExtraSmall ? "px-3 pt-0 pb-3" : "",
                                attrs: { cols: "12", sm: "4", md: "4" }
                              },
                              [
                                _c("v-switch", {
                                  staticClass: "ma-3",
                                  attrs: {
                                    color: "success",
                                    inset: "",
                                    "hide-details": "",
                                    label: _vm.comarca.requer_endosso
                                      ? "Sim"
                                      : "Não",
                                    "data-cy": "btnStatusComarca"
                                  },
                                  model: {
                                    value: _vm.comarca.requer_endosso,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.comarca,
                                        "requer_endosso",
                                        $$v
                                      )
                                    },
                                    expression: "comarca.requer_endosso"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }